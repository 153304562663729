<template>
  <div class="w-full">
    <div v-if="dateString">{{ dateString}}</div>
    <div v-else class="row m-0 flex w-full">
      <AppContentNone container-class-name="col-3 p-0" :content="dateObj.day" />/
      <AppContentNone container-class-name="col-3 p-0" :content="dateObj.month" />/
      <AppContentNone container-class-name="flex-1 p-0" :content="dateObj.year" />
    </div>
  </div>
</template>

<script>
import AppContentNone from './AppContentNone.vue'

export default {
  name: 'AppDateNone',
  props: {
    dateObj: {
      type: Object,
      default: () => ({})
    },
    dateString: String
  },
  components: { AppContentNone }
}
</script>

<style lang="scss" scoped>
</style>