<template>
  <div
    v-if="pagination.totalItems > 0"
    class="flex my-2 w-full flex-col justify-between items-center"
    :class="{ 'xl:flex-row': $route.name !== 'LiveCircleDetail' }"
  >
    <div class="fs-16 sm:mb-2 xl:mb-0">
      {{
        $t("fm_pagination", { countItems, totalItems: pagination.totalItems })
      }}
    </div>

    <div class="flex items-center gap-2">
      <el-pagination
        background
        layout="prev, pager, next"
        :pager-count="pagination.pagerCount"
        :page-size="pagination.pageSize"
        :total="pagination.totalItems"
        :current-page="pagination.currentPage"
        class="el-pagination-mdt"
        @next-click="handleChangePage"
        @prev-click="handleChangePage"
        @current-change="handleChangePage"
        :hide-on-single-page="isHideSinglePage"
        :disabled="disabled"
      >
        <slot></slot>
      </el-pagination>
      <div>
        <el-dropdown>
          <span
            class="block py-1.5 px-2 rounded-lg border bg-white text-gray-900 text-sm"
            role="button"
            style="white-space: nowrap"
          >
            <span>{{ pagination.pageSize }}</span>
            <span class="mx-1">/</span>
            <span>{{ $t("lbl_page") }}</span>
            <i class="el-icon-caret-bottom" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in SIZE_OF_PAGE_OPTIONS"
              :key="index"
            >
              <div @click="handleChangeSizeOfPage(item)">
                <span>{{ item }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export const DEFAULT_SIZE_OF_PAGE = 15
export const PAGER_COUNT_PAGINATION = 5
export const SIZE_OF_PAGE_OPTIONS = [10, 15, 18, 20, 50, 100]

export default {
  name: 'AppPagination',
  props: {
    paginationProps: {
      required: true,
      type: Object,
      default: function () {
        return {
          totalItems: 0,
          pagerCount: PAGER_COUNT_PAGINATION,
          pageSize: DEFAULT_SIZE_OF_PAGE,
          currentPage: 1
        }
      }
    },
    isHideSinglePage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      pagination: {},
      SIZE_OF_PAGE_OPTIONS
    }
  },

  mounted () {
    this.pagination = this.paginationProps
  },

  computed: {
    countItems () {
      return this.pagination.currentPage * this.pagination.pageSize >
        this.pagination.totalItems
        ? this.pagination.totalItems
        : this.pagination.currentPage * this.pagination.pageSize
    }
  },

  watch: {
    paginationProps: {
      deep: true,
      handler () {
        this.pagination = this.paginationProps
      }
    }
  },

  methods: {
    handleChangePage: debounce(function (newPage) {
      this.pagination.currentPage = newPage
      this.$emit('onChangePagination', { currentPage: newPage })
    }, 500),
    handleChangeSizeOfPage (newPageSize) {
      this.pagination.pageSize = newPageSize
      this.$emit('onChangePagination', {
        pageSize: newPageSize,
        currentPage: 1
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-pagination-mdt {
    padding: 0;

    & .number:hover {
      color: #20419b !important;
    }

    & .number.active {
      background-color: #20419b !important;
    }

    & .number.active:hover {
      background-color: #20419b !important;
      color: white !important;
    }
  }
}
</style>