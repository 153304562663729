<template>
  <div :class="containerClassName||'w-full'">
    <div :class="className" v-if="content">{{ content }}</div>
    <div v-else class="content-none my-3" :class="noContentClass||'w-full'"></div>
  </div>
</template>

<script>
export default {
  name: 'AppContentNone',
  props: {
    content: [String, Number],
    noContentClass: String,
    className: String,
    containerClassName: String
  }
}
</script>

<style lang="scss" scoped>
.content-none {
  width: 100%;
  border-bottom: 2px dotted;
}

@media print {
  .content-none {
    border-bottom: 1px dotted;
    margin: 13px 0 !important;
  }
}
</style>