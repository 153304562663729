<template>
  <div class="flex items-center">
    <div class="dropdown">
      <a class="btn dropdown-toggle border-0 bg-gray-100 robo-16-500 d-language px-2" type="button"
        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img :src="`./assets/images/language/${$lang}.png`" :alt="$lang" class="cursor-pointer mr-2" />
        <span class="d-none d-md-inline-block">
          {{
            $lang.toUpperCase() == LANGUAGE_CODES_NAME.VI
              ? $t("common.vi")
              : $lang.toUpperCase() == LANGUAGE_CODES_NAME.EN
                ? $t("common.en")
                : $lang.toUpperCase() == LANGUAGE_CODES_NAME.FR
                  ? $t("common.fr")
                  : $lang.toUpperCase() == LANGUAGE_CODES_NAME.JP
                    ? $t("common.jp")
                    : ""
          }}
        </span>
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a @click="changeLanguage(langCode.toLowerCase())" class="dropdown-item hover:bg-gray-100 d-flex robo-16-400"
          :class="index < Object.keys(LANGUAGE_CODES).length - 1 ? 'border-b border-solid border-gray-200 border-t-0 border-l-0 border-r-0' : ''"
          href="#" v-for="(langCode, index) in Object.keys(LANGUAGE_CODES)" :key="index">
          <img :src="`./assets/images/language/${langCode.toLowerCase()}.png`" alt class="cursor-pointer border mr-2"
            style="width:24px;height:16px" />
          <span>
            {{
              langCode == LANGUAGE_CODES_NAME.VI
                ? $t("common.vi")
                : langCode == LANGUAGE_CODES_NAME.EN
                  ? $t("common.en")
                  : langCode == LANGUAGE_CODES_NAME.FR
                    ? $t("common.fr")
                    : langCode == LANGUAGE_CODES_NAME.JP
                      ? $t("common.jp")
                      : ""
            }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LANGUAGE_CODES,
  LANGUAGE_CODES_NAME,
  LANGUAGE_KEYS,
  getTranslate
} from '../../lang'
import appUtils from '../../utils/appUtils'
export default {
  props: {
    action: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      LANGUAGE_KEYS,
      LANGUAGE_CODES,
      LANGUAGE_CODES_NAME
    }
  },
  methods: {
    getTranslate,
    async getMe () {
      const resp = await this.$rf.getRequest('AuthRequest').me()
      if (resp) {
        appUtils.setLocalUser(resp.data)
      }
    },
    changeLanguage (l) {
      const codeList = ['en', 'vi', 'fr', 'jp']
      const lang = codeList.find(item => item === l) ? l : 'vi'

      this.$lang = lang

      if (this.action) {
        const langId = appUtils.getLangId(lang)
        if (langId) this.saveLang(langId)
      }
    },
    async saveLang (id) {
      if (!this.$user) return
      await this.$rf
        .getRequest('AuthRequest')
        .setLanguageForUser({ lang: id })
        .then(() => {
          // this.$toast.open({
          //   message: 'Cập nhật thành công',
          //   type: 'success'
          // })
          this.getMe()
        })
    }
  }
}
</script>
<style lang="scss" scoped>
// .dropdown-item:hover {
//   background-color: rgba(209, 213, 219) !important;
// }</style>
